.rowChanged {
  background-color: #efdbff;
}
.differentPrice {
  background-color: #f9f0ff;
}
.expandedRow * {
  font-size: 13.5px;
}
.expandedRow .ant-table-tbody > tr > td,
.expandedRow .ant-table .ant-table-tbody > tr > td {
  padding-top: 4px;
  padding-bottom: 4px;
}
.disabledColor {
  color: #bfbfbf;
}
.d-none{
  display: none;
}
